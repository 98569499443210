import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL;

function SiteRaporlar() {

    const [refsLogData, setRefsLogData] = useState([]);
    const [, setLoading] = useState(false);
    const [, setError] = useState(null);

    const fetchLogsData = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/refs/reports/daily`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            setRefsLogData(response.data);
        } catch (error) {
            setError('Hata!!')
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchLogsData();
    }, []);
    const formatTurkishLira = (amount) => {
        const numAmount = Number(amount) || 0;
        return new Intl.NumberFormat('tr-TR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numAmount);
    };

    function convertToTurkeyTime(dateString) {
        // Verilen tarih dizesini Date objesine dönüştür
        let date = new Date(dateString);

        // Türkiye saati dilimi (GMT+3)
        let options = {
            timeZone: 'Europe/Istanbul',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        // Date objesini Türkiye saati ile formatla
        let turkeyTime = new Intl.DateTimeFormat('tr-TR', options).format(date);

        return turkeyTime;
    }

    return (

        <div className='top-0 bottom-0 h-screen right-0 left-0'>
            {/* Table */}
            <div className="overflow-x-auto">
                <table className="w-full container mx-auto  mt-2 border-collapse shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-800  text-white border-b-2 border-gray-200">
                        <tr className="font-sans">
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Tarih
                            </th>

                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Site
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Rapor ID
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Yatırım Miktarı
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Çekim Miktarı
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Yatırım Komisyonu
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Yatırım Komisyon Oranı
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Çekim Komisyonu
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Banka Çekim Komisyonu Oranı
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Kasa Takviyesi
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Kasa Takviye Çıkartma
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                Toplam
                            </th>
                        </tr>
                    </thead>

                    <tbody className="bg-white divide-y">
                        {refsLogData.length > 0 ? (refsLogData.map((refsLog) => (
                            <tr
                                key={refsLog.id}
                                className="bg-emerald-200 font-sans text-xs transition-colors duration-200"
                            >
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {convertToTurkeyTime(refsLog.report_date || 'N/A')}
                                </td>

                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {refsLog.ref_name|| 'N/A'}
                                </td>

                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {refsLog.id || 'N/A'}
                                </td>

                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {formatTurkishLira(refsLog.total_investments)} ₺
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {formatTurkishLira(refsLog.total_withdrawals)} ₺
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {formatTurkishLira(refsLog.investment_commission_earned)} ₺
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {refsLog.investment_commission_rate} %
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {refsLog.withdrawal_commission_rate} %
                                </td>


                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    {formatTurkishLira(refsLog.withdrawal_commission_earned)} ₺
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                <span className='p-1 rounded-md bg-emerald-400 text-slate-200'>{formatTurkishLira(refsLog.added_ref_balance)} ₺</span> 
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                   <span className='p-1 rounded-md bg-red-200'>{formatTurkishLira(refsLog.subtracted_ref_balance)} ₺</span> 
                                </td>
                                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                                    <span className="p-1 rounded-sm font-sans text-sm">
                                        {formatTurkishLira(refsLog.balance_left_from_commission)} ₺
                                    </span>
                                </td>
                            </tr>
                        ))) : (
                            <tr className='w-full'>
                                <td colSpan="12" className="text-center px-4 py-3">
                                    <h2 className="text-gray-500 text-sm my-3 font-medium">Veri Bulunamadı.</h2>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>


    )
}

export default SiteRaporlar
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const NavBar = () => {
  const token = localStorage.getItem('token');
  
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const decodedToken = token ? jwtDecode(token) : null;
  const userRole = decodedToken ? decodedToken.role : '';

  // Define report links conditionally based on user role
  const reportLinks = [
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/kasalar", text: "Kasalar" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/kasa_takviyeleri", text: "Kasa Takviyeleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/hesapkasa_takviyeleri", text: "Hesap Kasa Takviyeleri" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/hesap_istatistik", text: "Hesap İstatistikleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/hesap_eklemeleri", text: "Hesap Eklemeleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/yatırımlar", text: "Yatırımlar" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/cekimler", text: "Çekimler" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/siteler", text: "Siteler" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/site_istatistik", text: "Site İstatistik" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/site_raporları", text: "Site Raporları" }] : [])
  ];

  // Define main links
  const mainLinks = [
    { to: "/", text: "Anasayfa" },
    { to: "/yatırım-talepleri", text: "Yatırım Talepleri" },
    { to: "/cekim-talepleri", text: "Çekim Talepleri" },
    { to: "/hesap-yonetimi", text: "Hesap Yönetimi" },
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/yetki-yonetimi", text: "Yetki Yönetimi" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/admin-yonetimi", text: "Admin Yönetimi" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/yonetim", text: "Site Yönetimi" }] : []),
    ...(userRole === 'Admin' || userRole === 'Manager' || userRole === 'Master-Admin' || userRole === 'Ofis Müdürü' || userRole === 'Super Admin' ? [{ to: "/blacklist", text: "Blacklist" }] : [])
  ];

  return (
    <div className="w-full">
      <div className="py-3 gap-4 w-full flex-wrap hidden lg:flex bg-emerald-600 items-center justify-start pl-4">
        <nav className="flex items-start text-xs cursor-pointer bg-emerald-600 text-white font-bold flex-row justify-center">
          <ul className="flex items-start font-medium quicksand w-full gap-6 pl-3 justify-center">
            {mainLinks.map((link, index) => (
              <li key={index} className="hover:text-black duration-500 ease-in-out">
                <Link to={link.to}>{link.text}</Link>
              </li>
            ))}
            
            {reportLinks.length > 0 && (
              <li className="relative" style={{ zIndex: '9999' }} ref={dropdownRef}>
                <button
                  onClick={toggleMenu}
                  className="hover:text-black duration-50 ease-in-out flex items-center gap-1"
                >
                  Rapor Yönetimi
                  <svg
                    className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isOpen && (
                  <ul className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg py-1 z-50">
                    {reportLinks.map((link, index) => (
                      <li key={index}>
                        <Link
                          to={link.to}
                          className="block px-4 py-2 text-gray-800 hover:bg-emerald-50 hover:text-emerald-600 transition-colors duration-200"
                          onClick={() => setIsOpen(false)}
                        >
                          {link.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;

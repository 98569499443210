import React, { useState } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;


const AdminForm = ({ show, onClose, title }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    password: '',
    note: '',
    role: '',
    skype: '',
    email: '',
    is_active: false,
    is_admin: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const params = new URLSearchParams({
        username: formData.username,
        fullName: formData.fullName,
        password: formData.password,
        email: formData.email,
        role: formData.role,
        skype: formData.skype,
        note: formData.note,
        is_active: formData.is_active,
        is_admin: formData.is_admin
      });

      await axios.post(`${BASE_URL}/register?${params.toString()}`);

      setShowModal(true);
      
      setFormData({
        fullName: '',
        username: '',
        password: '',
        note: '',
        role: '',
        skype: '',
        email: '',
        is_active: false,
        is_admin: false
      });
      
    } catch (error) {
      console.error("Error:", error);
      setError(error.response?.data?.detail || 'An error occurred during registration');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  if (!show) return null;

  return (
    <div style={{
        zIndex: '9999',
    }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="bg-emerald-600 p-4 rounded-t-lg">
          <h1 className="text-white font-bold text-xl text-center">{title}</h1>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 mx-6">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Basic Information */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-bold mb-2">Adı Soyadı</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Adı Soyadı"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">Kullanıcı Adı</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Kullanıcı Adı"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">Şifre</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Şifreniz"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">E-Mail</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="E-Mail"
                  required
                />
              </div>
            </div>

            {/* Additional Information */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-bold mb-2">Not</label>
                <input
                  type="text"
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Notunuz"
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">Skype</label>
                <input
                  type="text"
                  name="skype"
                  value={formData.skype}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="Skype ID"
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">Rolü</label>
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                  required
                >
                  <option value="">Seçiniz</option>
                  <option value="Admin">Admin</option>
                  <option value="Manager">Yönetici</option>
                  <option value="Ofis Müdürü">Ofis müdürü</option>
                  <option value="Teknik">Teknik</option>
                  <option value="Personel">Personel</option>
                </select>
              </div>

              <div className="flex gap-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="is_active"
                    checked={formData.is_active}
                    onChange={handleChange}
                    className="w-4 h-4"
                  />
                  <span className="text-sm font-bold">Hesap Aktifliği</span>
                </label>

         
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              type="submit"
              disabled={isLoading}
              className="bg-emerald-500 text-white px-4 text-xs py-2 rounded-md hover:bg-emerald-600 disabled:opacity-50"
            >
              {isLoading ? 'Admin Ekleniyor...' : 'Admin Ekle'}
            </button>

            <button
              type="button"
              onClick={onClose}
              disabled={isLoading}
              className="bg-red-500 text-white px-4 text-xs py-2 rounded-md hover:bg-red-600 disabled:opacity-50"
            >
              Kapat
            </button>
          </div>

          <div className="text-center text-sm text-gray-600 bg-gray-100 p-4 rounded-md mt-6">
            <p className="font-semibold">Lütfen tüm alanları eksiksiz doldurunuz.</p>
            <p>Anlayışınız için teşekkür ederiz.</p>
            <p>Dem<span className="font-bold">Fix</span></p>
          </div>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Admin Başarıyla Eklendi</h2>
            <button
              onClick={handleCloseModal}
              className="bg-emerald-500 text-white px-4 py-2 rounded-md hover:bg-emerald-600"
            > 
            Kapat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminForm;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL


const RefsTable = () => {
  const [refs, setRefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedRefId, setSelectedRefId] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [formData, setFormData] = useState({
    refName: '',
    refUrl: '',
    subDomain: '',
    refsApi: false,
    refsBank: false,
    refsCrypto: false,
    isActive: true,
    investmentCommissionRate: 0.0,
    withdrawalCommissionRate: 0.0,
    hasInvestmentCommission: false,
    hasWithdrawalCommission: false
  });


  const fetchRefs = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/refs/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      const sortedRefs = response.data.data.sort((a, b) =>
        a.refName.localeCompare(b.refName)
      );
      setRefs(sortedRefs);
      setError(null);
    } catch (error) {
      console.error("Error fetching refs:", error);
      setError("Failed to load refs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchRefs();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token bulunamadı.");
      return;
    }

    // Convert commission rates to numbers and ensure they're within valid range
    const investmentRate = Math.max(0, Math.min(100, parseFloat(formData.investmentCommissionRate) || 0));
    const withdrawalRate = Math.max(0, Math.min(100, parseFloat(formData.withdrawalCommissionRate) || 0));

    try {
      if (isCreating) {
        const params = new URLSearchParams({
          refName: formData.refName.trim(),
          refUrl: formData.refUrl.trim(),
          subDomain: formData.subDomain.trim(),
          refsApi: formData.refsApi.toString(),
          refsBank: formData.refsBank.toString(),
          refsCrypto: formData.refsCrypto.toString(),
          isActive: formData.isActive.toString(),
          investmentCommissionRate: investmentRate.toString(),
          withdrawalCommissionRate: withdrawalRate.toString(),
          hasInvestmentCommission: formData.hasInvestmentCommission.toString(),
          hasWithdrawalCommission: formData.hasWithdrawalCommission.toString()
        });

        await axios.post(`${BASE_URL}/refs/?${params.toString()}`, null, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
      } else {
        const payload = {
          refName: formData.refName.trim(),
          refUrl: formData.refUrl.trim(),
          subDomain: formData.subDomain.trim(),
          refsApi: Boolean(formData.refsApi),
          refsBank: Boolean(formData.refsBank),
          refsCrypto: Boolean(formData.refsCrypto),
          isActive: Boolean(formData.isActive),
          investmentCommissionRate: investmentRate,
          withdrawalCommissionRate: withdrawalRate,
          hasInvestmentCommission: Boolean(formData.hasInvestmentCommission),
          hasWithdrawalCommission: Boolean(formData.hasWithdrawalCommission)
        };

        await axios.patch(`${BASE_URL}/refs/${selectedRefId}`, payload, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
      }

      await fetchRefs();
      resetForm();
    } catch (error) {
      console.error("Error details:", error.response?.data);
      setError(error.response?.data?.detail || "İşlem sırasında bir hata oluştu.");
    }
  };


  const resetForm = () => {
    setFormData({
      refName: '',
      refUrl: '',
      subDomain: '',
      refsApi: false,
      refsBank: false,
      refsCrypto: false,
      isActive: true,
      investmentCommissionRate: 0.0,
      withdrawalCommissionRate: 0.0,
      hasInvestmentCommission: false,
      hasWithdrawalCommission: false
    });
    setIsCreating(false);
    setShowUpdateForm(false);
  };
  const handleDelete = async (refId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${BASE_URL}/refs/${refId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await fetchRefs();
      setShowDeleteConfirm(false);
      setSelectedRefId(null);
    } catch (error) {
      console.error("Error deleting ref:", error);
      setError("Failed to delete ref. Please try again.");
    }
  };
  const filteredRefs = refs.filter(ref =>
    ref.refName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ref.refUrl.toLowerCase().includes(searchTerm.toLowerCase())
  );


  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500" />
      </div>
    );
  }



  return (
    <div className='top-0 botttom-0 left-0 right-0 h-screen'>
    <div className="w-full p-4">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 flex justify-between items-center border-b">
          <input
            type="text"
            placeholder="Site Ara..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-1 text-xs border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
          />
          <button
            onClick={() => setIsCreating(true)}
            className="px-2 py-1 bg-emerald-500 text-white text-sm rounded-lg hover:bg-emerald-600"
          >
            Site Ekle
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-800">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                  Site
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                  Komisyonlar
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">
                  Özellikler
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">
                  Durum
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">
                  İşlemler
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredRefs.map((ref) => (
                <tr key={ref.refId} className="hover:bg-gray-100 ">
                  <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                    <div className="text-xs font-medium text-gray-900">{ref.refName}</div>
                 
                    <div className="text-xs text-gray-500">{ref.refUrl}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                    <div className="text-xs">
                      {ref.hasInvestmentCommission && (
                        <div className="text-emerald-600">YATIRIM: {ref.investmentCommissionRate} %</div>
                      )}
                      {ref.hasWithdrawalCommission && (
                        <div className="text-blue-600">ÇEKİM: {ref.withdrawalCommissionRate }%</div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <div className="flex justify-center space-x-2">
                      <span className={`px-2 py-1 text-xs rounded-full ${ref.refsApi ? 'bg-emerald-100 text-emerald-800' : 'bg-gray-100 text-gray-800'}`}>
                        API
                      </span>
                      <span className={`px-2 py-1 text-xs rounded-full ${ref.refsBank ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}>
                        Banka
                      </span>
                      <span className={`px-2 py-1 text-xs rounded-full ${ref.refsCrypto ? 'bg-purple-100 text-purple-800' : 'bg-gray-100 text-gray-800'}`}>
                        Kripto
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <span className={`px-2 py-1 text-xs rounded-full ${ref.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {ref.isActive ? 'Aktif' : 'Pasif'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <button
                        onClick={() => {
                          setSelectedRefId(ref.refId);
                          setFormData({
                            refName: ref.refName,
                            refUrl: ref.refUrl,
                            subDomain: ref.subDomain,
                            refsApi: ref.refsApi,
                            refsBank: ref.refsBank,
                            refsCrypto: ref.refsCrypto,
                            isActive: ref.isActive,
                            investmentCommissionRate: ref.investmentCommissionRate,
                            withdrawalCommissionRate: ref.withdrawalCommissionRate,
                            hasInvestmentCommission: ref.hasInvestmentCommission,
                            hasWithdrawalCommission: ref.hasWithdrawalCommission
                          });
                          setShowUpdateForm(true);
                        }}
                        className="px-3 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                      >
                        Düzenle
                      </button>
                     
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
      {/* Form Modal */}
      {(isCreating || showUpdateForm) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                {isCreating ? 'Site Ekle' : 'Site Düzenle'}
              </h2>
              <button
                onClick={resetForm}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                ✕
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Site Referans Adı
                </label>
                <input
                  type="text"
                  value={formData.refName}
                  onChange={(e) => setFormData({ ...formData, refName: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  URL
                </label>
                <input
                  type="text"
                  value={formData.refUrl}
                  onChange={(e) => setFormData({ ...formData, refUrl: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Alt Domain
                </label>
                <input
                  type="text"
                  value={formData.subDomain}
                  onChange={(e) => setFormData({ ...formData, subDomain: e.target.value })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                  required
                />
              </div>
              <div className="space-y-4">
                <div>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.hasInvestmentCommission}
                      onChange={(e) => setFormData({ ...formData, hasInvestmentCommission: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">Yatırım Komisyonu</span>
                  </label>
                  {formData.hasInvestmentCommission && (
                    <div className="mt-2">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="0.1"
                        value={formData.investmentCommissionRate}
                        onChange={(e) => setFormData({
                          ...formData,
                          investmentCommissionRate: parseFloat(e.target.value) || 0
                        })}
                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                        placeholder="Yatırım Komisyon Oranı (%)"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.hasWithdrawalCommission}
                      onChange={(e) => setFormData({ ...formData, hasWithdrawalCommission: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">Çekim Komisyonu</span>
                  </label>
                  {formData.hasWithdrawalCommission && (
                    <div className="mt-2">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="0.1"
                        value={formData.withdrawalCommissionRate}
                        onChange={(e) => setFormData({
                          ...formData,
                          withdrawalCommissionRate: parseFloat(e.target.value) || 0
                        })}
                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                        placeholder="Çekim Komisyon Oranı (%)"
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap gap-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.refsApi}
                      onChange={(e) => setFormData({ ...formData, refsApi: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">API</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.refsBank}
                      onChange={(e) => setFormData({ ...formData, refsBank: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">Banka</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.refsCrypto}
                      onChange={(e) => setFormData({ ...formData, refsCrypto: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">Kripto</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.isActive}
                      onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                      className="rounded border-gray-300"
                    />
                    <span className="ml-2 text-sm text-gray-700">Aktif</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                >
                  İptal
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600"
                >
                  {isCreating ? 'Ekle' : 'Güncelle'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
   
      {/* Error Message */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg">
          <div className="flex items-center">
            <span>{error}</span>
            <button
              onClick={() => setError(null)}
              className="ml-3 p-1 hover:bg-red-200 rounded-full"
            >
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};
export default RefsTable;
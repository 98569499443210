import React, { useState, useEffect } from 'react';
import { IoIosClose } from "react-icons/io";
import { FaUser, FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const TransferBox = ({ show, onClose }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTermSender, setSearchTermSender] = useState('');
    const [searchTermRecipient, setSearchTermRecipient] = useState('');
    const [selectedSenderAccount, setSelectedSenderAccount] = useState(null);
    const [selectedRecipientAccount, setSelectedRecipientAccount] = useState(null);
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState('');
    const [transferSuccess, setTransferSuccess] = useState(false);
    const [showSenderList, setShowSenderList] = useState(false);
    const [showRecipientList, setShowRecipientList] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found');
            }
            const response = await axios.get(`${BASE_URL}/bank/accounts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            if (response.data && response.data.data) {
                const sortedUsers = response.data.data.sort((a, b) =>
                    new Date(b.created_at) - new Date(a.created_at)
                );
                setUsers(sortedUsers);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error("Error fetching accounts:", error);
            setError(error.response?.data?.detail || error.message || 'Failed to fetch accounts');
            setUsers([]);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (show) {
            fetchData();
        }
    }, [show]);
    const filterUsers = (users, searchTerm, excludeAccountId = null) => {
        return users.filter(user => 
            user.accountId !== excludeAccountId &&
            (user.accountNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
             user.accountName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
             user.bankName?.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };
    const handleTransfer = async () => {
        if (!selectedSenderAccount || !selectedRecipientAccount || !amount) {
            alert("Lütfen her iki hesabı ve transfer miktarını giriniz.");
            return;
        }
        if (selectedSenderAccount.accountId === selectedRecipientAccount.accountId) {
            alert("Aynı hesaba transfer yapamazsınız!");
            return;
        }
        if (parseFloat(amount) <= 0) {
            alert("Lütfen geçerli bir miktar giriniz.");
            return;
        }
        if (parseFloat(amount) > selectedSenderAccount.accountBalance) {
            alert("Yetersiz bakiye!");
            return;
        }
        try {
            setLoading(true);
            setError(null);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found');
            }
            const transfer = {
                sender_account_id: selectedSenderAccount.accountId,
                receiver_account_id: selectedRecipientAccount.accountId,
                amount: parseInt(amount),
                note: note || 'Transfer işlemi'
            };
            await axios.post(`${BASE_URL}/bank/transfer`, transfer, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setTransferSuccess(true);
            await fetchData();
            setSearchTermSender('');
            setSearchTermRecipient('');
            setSelectedSenderAccount(null);
            setSelectedRecipientAccount(null);
            setAmount('');
            setNote('');
            setTimeout(() => {
                setTransferSuccess(false);
                onClose();
            }, 2000);
        } catch (error) {
            console.error("Transfer error:", error);
            setError(error.response?.data?.detail || error.message || 'Transfer işlemi başarısız oldu');
        } finally {
            setLoading(false);
        }
    };
    const handleAccountSelect = (user, isSender) => {
        if (isSender) {
            setSelectedSenderAccount(user);
            setSearchTermSender(`${user.accountName} - ${user.bankName} (${user.accountNumber})`);
            setShowSenderList(false);
        } else {
            setSelectedRecipientAccount(user);
            setSearchTermRecipient(`${user.accountName} - ${user.bankName} (${user.accountNumber})`);
            setShowRecipientList(false);
        }
    };
    const handleInputChange = (value, isSender) => {
        if (isSender) {
            setSearchTermSender(value);
            setSelectedSenderAccount(null);
            setShowSenderList(true);
        } else {
            setSearchTermRecipient(value);
            setSelectedRecipientAccount(null);
            setShowRecipientList(true);
        }
    };
    const renderAccountList = (filteredUsers, isSender) => (
        <div className="absolute z-10 w-full bg-white shadow-lg rounded-md border border-gray-200 mt-1 max-h-48 overflow-y-auto">
            {loading ? (
                <div className="p-3 text-gray-500 text-sm">Yükleniyor...</div>
            ) : error ? (
                <div className="p-3 text-red-500 text-sm">{error}</div>
            ) : filteredUsers.length > 0 ? (
                filteredUsers.map(user => (
                    <div
                        key={user.accountId}
                        className="p-3 hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                        onClick={() => handleAccountSelect(user, isSender)}
                    >
                        <div className="text-sm font-medium text-gray-900">
                            {user.accountName} - {user.bankName}
                        </div>
                        <div className="text-xs text-gray-500">
                            {user.accountNumber}
                            <span className="ml-2 text-emerald-500">
                                Bakiye: {user.accountBalance.toLocaleString()}₺
                            </span>
                        </div>
                    </div>
                ))
            ) : (
                <div className="p-3 text-sm text-gray-500">Hesap bulunamadı</div>
            )}
        </div>
    );
    if (!show) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-hidden">
                <div className="bg-yellow-600 px-6 py-4 flex items-center justify-between">
                    <div className="flex items-center space-x-2 text-white">
                        <FaMoneyBillTransfer className="text-xl" />
                        <h2 className="text-lg text-slate-200 font-sans font-medium">Hesaplar Arası Transfer</h2>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-white hover:text-gray-200 transition-colors"
                        disabled={loading}
                    >
                        <IoIosClose className="text-2xl" />
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    {error && (
                        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
                            {error}
                        </div>
                    )}
                    {transferSuccess && (
                        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded-md flex items-center justify-center">
                            Transfer başarıyla gerçekleşti
                        </div>
                    )}
                    <div className="space-y-2 relative">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaUser className="mr-2" />
                            Gönderen Hesap
                        </label>
                        <input
                            type="text"
                            placeholder="Hesap sahibi veya hesap numarası"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                            value={searchTermSender}
                            onChange={(e) => handleInputChange(e.target.value, true)}
                            onFocus={() => setShowSenderList(true)}
                            disabled={loading}
                        />
                        {showSenderList && renderAccountList(filterUsers(users, searchTermSender), true)}
                    </div>
                    <div className="space-y-2 relative">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaUser className="mr-2" />
                            Alıcı Hesap
                        </label>
                        <input
                            type="text"
                            placeholder="Hesap sahibi veya hesap numarası"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                            value={searchTermRecipient}
                            onChange={(e) => handleInputChange(e.target.value, false)}
                            onFocus={() => setShowRecipientList(true)}
                            disabled={loading}
                        />
                        {showRecipientList && renderAccountList(filterUsers(users, searchTermRecipient, selectedSenderAccount?.accountId), false)}
                    </div>
                    <div className="space-y-2">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaMoneyBillWave className="mr-2" />
                            Transfer Miktarı
                        </label>
                        <input
                            type="number"
                            placeholder="0"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            disabled={loading}
                            min="0"
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            Açıklama
                        </label>
                        <input
                            type="text"
                            placeholder="Transfer açıklaması"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="flex justify-end pt-4">
                        <button
                            onClick={handleTransfer}
                            className="px-6 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={loading || !selectedSenderAccount || !selectedRecipientAccount || !amount}
                        >
                            {loading ? 'İşlem yapılıyor...' : 'Transfer Et'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TransferBox;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DateInput from './DateInput';

const BASE_URL = process.env.REACT_APP_API_URL;

const Siteİstatistik = () => {
  const [refsLogsData, setRefsLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const fetchRefsLogsData = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      let url = `${BASE_URL}/refs/daily/stats`;
      
      // Add date parameter if a specific date is selected
      if (selectedDate) {
        const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
        url += `?target_date=${formattedDate}`;
      }

      const response = await axios.get(url, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      // Filter out logs where refName is 'mgmt'
      const filteredData = response.data.filter(refsLog => refsLog.refName !== 'mgmt');
      
      setRefsLogsData(filteredData);
    } catch (error) {
      setError('Veri hatası');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    setSelectedDate(dates.startDate);
  };

  useEffect(() => {
    fetchRefsLogsData();
  }, [selectedDate]);

  const formatTurkishLira = (amount) => {
    const numAmount = Number(amount) || 0;
    return new Intl.NumberFormat('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numAmount);
  };

  return (
    <div className="top-0 bottom-0 h-screen right-0 left-0">
      <div className="w-full overflow-x-auto">
        <div className="my-4 p-3 border-2">
          <DateInput 
            onDateChange={handleDateChange}
            singleDate={true}
          />
        </div>
        {loading ? (
          <div className="text-center p-4">Yükleniyor...</div>
        ) : error ? (
          <div className="text-center text-red-500 p-4">{error}</div>
        ) : (
          <>
            {/* Tablo */}
            <div className="overflow-x-auto">
              <table className="w-full container mx-auto border-collapse shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-800 text-white border-b-2 border-gray-200">
                  <tr className="font-sans">
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Site
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Yatırım Miktarı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Yatırım Sayısı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Çekim Miktarı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Çekim Sayısı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Yatırım Oranı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Çekim Oranı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Yatırım Komisyon Miktarı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Çekim Komisyon Miktarı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Toplam Komisyon Miktarı
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                      Site Kasa
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y">
                  {refsLogsData.length > 0 ? (
                    refsLogsData.map((refsLog, index) => (
                      <tr
                        key={index}
                        className="bg-emerald-200 font-sans text-xs transition-colors duration-200"
                      >
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                          {refsLog.refName || 'N/A'}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {formatTurkishLira(refsLog.period_regular_investments)} ₺
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {refsLog.period_investment_count} adet
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {formatTurkishLira(refsLog.period_regular_withdrawals)} ₺
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {refsLog.period_withdrawal_count} adet
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {refsLog.investmentCommissionRate} %
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {refsLog.withdrawalCommissionRate} %
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {formatTurkishLira(refsLog.period_earned_investment_commission)} ₺
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {formatTurkishLira(refsLog.period_earned_withdrawal_commission)} ₺
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          {formatTurkishLira(refsLog.period_total_earned_commission)} ₺
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                          <span className="p-1 rounded-sm font-sans text-sm">
                            {formatTurkishLira(refsLog.current_period_balance)} ₺
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-full">
                      <td colSpan="11" className="text-center px-4 py-3">
                        <h2 className="text-gray-500 text-sm my-3 font-medium">Veri Bulunamadı.</h2>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Summary Section */}
            <div className="border-2 bg-slate-200 container mx-auto flex gap-4 flex-wrap flex-col cursor-pointer rounded-md mt-4 p-6">
              <div className="flex flex-col md:flex-row justify-center gap-6">
                <div className="w-full md:w-80 font-sans bg-emerald-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Yatırım Sayısı</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {refsLogsData.reduce((total, log) => total + Number(log.period_investment_count), 0)} adet
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-80 font-sans bg-red-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Çekim Sayısı</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {refsLogsData.reduce((total, log) => total + Number(log.period_withdrawal_count), 0)} adet
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-6">
                <div className="w-full md:w-80 font-sans bg-emerald-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Yatırım Miktarı</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {formatTurkishLira(
                        refsLogsData.reduce((total, log) => total + Number(log.period_regular_investments), 0)
                      )} ₺
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-80 font-sans bg-red-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Çekim Miktarı</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {formatTurkishLira(
                        refsLogsData.reduce((total, log) => total + Number(log.period_regular_withdrawals), 0)
                      )} ₺
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-6">
                <div className="w-full md:w-80 font-sans bg-emerald-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Yatırım Komisyonu</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {formatTurkishLira(
                        refsLogsData.reduce((total, log) => total + Number(log.period_earned_investment_commission), 0)
                      )} ₺
                    </span>
                  </div>
                </div>
                <div className="w-full md:w-80 font-sans bg-red-200 p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Çekim Komisyonu</h3>
                  <div className="text-xl text-center font-bold text-gray-600">
                    <span className="p-2 bg-white rounded-md">
                      {formatTurkishLira(
                        refsLogsData.reduce((total, log) => total + Number(log.period_earned_withdrawal_commission), 0)
                      )} ₺
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-full md:w-80 font-sans bg-gray-600 text-white p-6 rounded-lg">
                  <h3 className="text-lg text-center font-bold text-gray-200 mb-3">Toplam Komisyon Miktarı</h3>
                  <div className="text-xl text-center font-bold text-gray-700">
                    <span className="p-2 bg-emerald-500 text-white rounded-md">
                      {formatTurkishLira(
                        refsLogsData.reduce((total, log) => total + Number(log.period_total_earned_commission), 0)
                      )} ₺
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Siteİstatistik;

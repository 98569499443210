import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import "../css/sideBar.css";  // CSS dosyası
import { FaBarsStaggered, FaChevronDown } from "react-icons/fa6";
import { jwtDecode } from 'jwt-decode';


const Sidebar = () => {

  const token = localStorage.getItem('token');

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setIsOpen2(!isOpen2);
  };

  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.role;

  const reportLinks = [
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/kasalar", text: "Kasalar" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/kasa_takviyeleri", text: "Kasa Takviyeleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/hesapkasa_takviyeleri", text: "Hesap Kasa Takviyeleri" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/hesap_istatistik", text: "Hesap İstatistikleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/hesap_eklemeleri", text: "Hesap Eklemeleri" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/yatırımlar", text: "Yatırımlar" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/cekimler", text: "Cekimler" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/rapor-yonetimi/siteler", text: "Siteler" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/site_istatistik", text: "Site İstatistik" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Manager' ||  userRole === 'Super Admin' ? [{ to: "/rapor-yonetimi/site_raporları", text: "Site Raporları" }] : [])
  ];

  const mainLinks = [
    { to: "/", text: "Anasayfa" },
    { to: "/yatırım-talepleri", text: "Yatırım Talepleri" },
    { to: "/cekim-talepleri", text: "Çekim Talepleri" },
    { to: "/hesap-yonetimi", text: "Hesap Yönetimi" },

    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' || userRole === 'Ofis Müdürü' || userRole === 'Manager' ? [{ to: "/yetki-yonetimi", text: "Yetki Yönetimi" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/admin-yonetimi", text: "Admin Yönetimi" }] : []),
    ...(userRole === 'Master-Admin' || userRole === 'Super Admin' ? [{ to: "/yonetim", text: "Site Yönetimi" }] : []),
    ...(userRole === 'Admin' || userRole === 'Master-Admin'  || userRole === 'Ofis Müdürü', userRole === 'Super Admin' ? [{ to: "/blacklist", text: "Blacklist" }] : []),

  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);  // Eğer tıklanan yer sidebar değilse kapat
      }
    };

    // Tıklama olayını dinleme
    window.addEventListener('click', handleClickOutside);

    // Temizlik: Bileşen unmount olduğunda dinleyiciyi kaldır
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="z-50">
        <FaBarsStaggered onClick={toggleSidebar} className="text-white" size={15} style={{ cursor: 'pointer' }} />

        {/* Soldan gelen menü */}
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <ul className='flex flex-col p-5 mt-1 ' style={{
            zIndex: '200'
          }}>
                {mainLinks.map((link, index) => (
              <li key={index} className="hover:text-black duration-500 ease-in-out">
                <Link to={link.to}>{link.text}</Link>
              </li>
            ))}
            
            {/* Rapor Yönetimi Dropdown */}
            <li className="relative" >
              <button
                onClick={toggleMenu}
                className="flex items-center gap-2 text-black hover:text-white px-0  py-0 rounded-md transition-all duration-200"
              >
                Rapor Yönetimi
                <FaChevronDown 
                  className={`transition-transform ${isOpen2 ? 'rotate-180' : ''}`} 
                  size={16} 
                />
              </button>

              {isOpen2 && (
                <ul className="absolute left-0 mt-2 w-full bg-transparent border-gray-200 rounded-lg shadow-lg py-0 z-50">
                  {reportLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.to}
                        className="block px-3 py-0 text-gray-800  hover:text-emerald-600 transition-colors duration-200"
                        onClick={() => setIsOpen2(false)}
                      >
                        - {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import axios from 'axios';
import DateInput from './DateInput';

const BASE_URL = process.env.REACT_APP_API_URL;

function HesapEklemeleri() {

  const [hesapEklemeleri, setHesapEklemeleri] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ startDate: '', endDate: '' });

  const handleDateChange = (dates) => {
    setFilters(dates);
  };

  const fetchHesapEklemeleri = async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      let url = `${BASE_URL}/bank/reports/daily`;
      if (filters.startDate && filters.endDate) {
        url = `${url}?start_date=${filters.startDate}&end_date=${filters.endDate}`;
      }
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      setHesapEklemeleri(response.data);
    } catch (error) {
      console.error(error);
      setError('Veri alınırken hata');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHesapEklemeleri();
  }, [filters]); 

  // Format the created_at date to Turkey's local date format
  const formatDateToTurkey = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('tr-TR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <NavBar />
      <div style={{ boxShadow: '2px 3px 5px grey' }} className="container mt-6 border-gray-500 mx-auto p-6">
        <div className="my-2 mb-4">
          <DateInput onDateChange={handleDateChange} />
        </div>
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full text-sm text-gray-700">
            <thead className="bg-gray-700 text-slate-200">
              <tr className="font-sans uppercase">
                <th className="py-3 px-6 border-2 text-left">Tarih ve Saat</th>
                <th className="py-3 px-6 border-2 text-left">Eklenen</th>
                <th className="py-3 px-6 border-2 text-left">Silinen</th>
                <th className="py-3 px-6 border-2 text-left">İade Edilen</th>
              </tr>
            </thead>
            <tbody>
              {/* Hesap Eklemeleri */}
              {hesapEklemeleri.length > 0 ? (hesapEklemeleri.map((item, index) => (
                <tr key={index} className="bg-emerald-100 font-sans text-xs font-semibold border-2">
                  <td className="py-4 border-2 px-6">{formatDateToTurkey(item.report_date)}</td>
                  <td className="py-4 border-2 px-6">{item.accounts_added}</td>
                  <td className="py-4 border-2 px-6">{item.accounts_deleted}</td>
                  <td className="py-4 border-2 px-6">{item.account_refunded || 0}</td>
                </tr>
              ))) : (
          
                     <div className="text-center py-6 col-span-4 text-gray-500">
                        Aradığın kriterde bir hesap bulunamadı.
                    </div>
       
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HesapEklemeleri;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Krediİmg from '../img/kredi-karti.svg';

const BASE_URL = process.env.REACT_APP_API_URL;


function BankaHesaplar() {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getAllByUsers = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/bank/accounts`, 
                { headers: { 'Authorization': `Bearer ${token}` } });
            setUsers(response.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load account data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getAllByUsers();
    }, []);

    
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 ">
                <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Error</h3>
                            <div className="mt-2 text-sm text-red-700">{error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const totalBalance = users.reduce((acc, data) => acc + data.accountBalance, 0);

    return (
        <div className="md:max-w-sm w-full ">
            <div className="bg-white rounded-md shadow-md shadow-black overflow-hidden">
                <div className="bg-emerald-600 px-6 py-3">
                    <h2 className="text-lg font-semibold text-white justify-between gap-2 flex items-center">

                        Tüm Banka Hesapları
                        <img src={Krediİmg} className='h-8' alt='kredi-img' />
                    </h2>
                </div>

                {/* Total Balance Card */}
                <div className="p-6">
                    <div className="bg-emerald-100 flex items-center justify-center flex-col rounded-lg p-4 mb-6">
                        <h3 className="text-base font-medium underline underline-offset-4 text-gray-600 mb-2">Toplam Miktar</h3>
                        <div className="text-2xl font-bold text-emerald-600">
                            {totalBalance.toLocaleString('tr-TR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })} ₺
                        </div>
                    </div>

                    {/* Account List */}
                    <div className="space-y-6">
                        {users.map((account) => (
                            <div
                                key={account.id}
                                className="bg-white rounded-lg border border-gray-200 p-4 hover:shadow-md transition-shadow"
                            >
                                <div className="flex flex-col space-y-2">
                                    <div className="flex items-center text-sm justify-between">
                                        <span className="font-medium text-sm text-gray-900">
                                            {account.accountName}
                                        </span>
                                        <span className="text-sm text-gray-500">
                                            {account.bankName}
                                        </span>
                                    </div>

                                    <div className={`text-right text-sm font-semibold ${account.accountBalance === 0
                                        ? 'text-gray-600'
                                        : account.accountBalance < 5000
                                            ? 'text-yellow-600'
                                            : 'text-emerald-600'
                                        }`}>
                                        {Number(account.accountBalance).toLocaleString("tr-TR", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })} ₺
                                    </div>

                                    <div className="flex items-center justify-between text-xs text-gray-500">
                                        <span>Oluşturulma Tarihi:</span>
                                        <span>{new Date(account.created_at).toLocaleString("tr-TR")}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankaHesaplar
import React, { useState, useEffect } from 'react';
import DateTime from '../components/DateTime';
import { FaMoneyBillTransfer, FaChartLine, FaCircleCheck, FaClock } from 'react-icons/fa6';
import Bankaİmg from '../img/banka-havalesi.png';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const BankaCardBox = () => {
  const [bankKasa, setBankKasa] = useState(0);
  const [bankActive, setBankActive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refsLogsData, setRefsLogsData] = useState([]);
  const [totals, setTotals] = useState({
    approved: {
      investments: { amount: 0, count: 0 },
      withdrawals: { amount: 0, count: 0 }
    },
    pending: {
      investments: { amount: 0, count: 0 },
      withdrawals: { amount: 0, count: 0 }
    }
  });
  const [hesapPaylasimi, setHesapPaylasimi] = useState(false);
  const formatTurkishLira = (amount) => {
    const numAmount = Number(amount) || 0;
    return new Intl.NumberFormat('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numAmount);
  };
  const fetchDailyStats = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/refs/daily/stats`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setRefsLogsData(response.data);
      // Calculate totals from all refs
      const newTotals = response.data.reduce((acc, ref) => ({
        approved: {
          investments: {
            amount: acc.approved.investments.amount + Number(ref.period_regular_investments || 0),
            count: acc.approved.investments.count + Number(ref.period_investment_count || 0)
          },
          withdrawals: {
            amount: acc.approved.withdrawals.amount + Number(ref.period_regular_withdrawals || 0),
            count: acc.approved.withdrawals.count + Number(ref.period_withdrawal_count || 0)
          }
        },
        pending: {
          investments: {
            amount: acc.pending.investments.amount + Number(ref.period_pending_investments || 0),
            count: acc.pending.investments.count + Number(ref.period_pending_investment_count || 0)
          },
          withdrawals: {
            amount: acc.pending.withdrawals.amount + Number(ref.period_pending_withdrawals || 0),
            count: acc.pending.withdrawals.count + Number(ref.period_pending_withdrawal_count || 0)
          }
        }
      }), {
        approved: {
          investments: { amount: 0, count: 0 },
          withdrawals: { amount: 0, count: 0 }
        },
        pending: {
          investments: { amount: 0, count: 0 },
          withdrawals: { amount: 0, count: 0 }
        }
      });
      setTotals(newTotals);
    } catch (error) {
      console.error("Daily stats getirilirken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchBankActiveData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${BASE_URL}/bank/accounts`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const bankActiveData = response.data.data;
      setBankActive(bankActiveData);
      // active
      const activeAccounts = bankActiveData.filter(account => account.isInvest === true);
      setHesapPaylasimi(activeAccounts.length > 0);
    } catch (error) {
      setError('Veri alınırken hata.');
    }
  };


  const fetchKasa = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${BASE_URL}/refs/with_investments/count`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const mgmtKasaData = response.data.filter(item => item.refName === 'mgmt');
      if (mgmtKasaData.length > 0) {
        const mgmtKasaBalance = mgmtKasaData[0].balance || 0; 
        setBankKasa(mgmtKasaBalance);
      } else {
        setError('Mgmt kasası bulunamadı.');
      }
      
    } catch (error) {
      setError('Veri alınırken hata');
    }
  };

  

  useEffect(() => {
    fetchDailyStats();
    fetchBankActiveData();
    fetchKasa();
  }, []);
  return (
    <div className="bg-white rounded-lg overflow-hidden w-[22rem]">
      {/* Başlık */}
      <div className="bg-blue-600 px-6 py-2">
        <div className="flex items-center justify-between">
          <h2 className="text-xs font-semibold text-white">Banka Ödeme</h2>
          <img src={Bankaİmg} alt="kredi-img" className="h-10" />
        </div>
      </div>
      {/* İçerik */}
      <div className="p-3 space-y-4">
        {/* Tarih-Saat */}
        <div className="flex items-center text-xs text-gray-500">
          <DateTime />
        </div>
        {/* Toplam Bakiye */}
        <div className="bg-emerald-50 rounded-lg p-1">
          <div className="flex items-center justify-between">
            <span className="text-xs font-medium text-gray-600">Kasa: </span>
            <span className="text-sm font-bold text-emerald-600">
              {formatTurkishLira(bankKasa)} ₺
            </span>
          </div>
        </div>
        {/* Rest of the component remains the same */}
        {/* Hesap Paylaşımı */}
        <div className="bg-gray-50 rounded-lg p-1">
          <div className="flex items-center justify-between mb-2">
            <span className="text-xs font-medium text-gray-600">Hesap Paylaşımı</span>
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                hesapPaylasimi ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}
            >
              {hesapPaylasimi ? 'Var' : 'Yok'}
            </span>
          </div>
        </div>
        {/* Yatırımlar ve Çekimler */}
        <div className="space-y-4">
          {/* Yatırım Bölümü */}
          <div className="rounded-lg border border-gray-200 px-4 py-1">
            <h3 className="text-xs font-medium text-gray-700 mb-4 flex items-center">
              <FaChartLine className="mr-2 text-emerald-500" />
              Yatırım
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {/* Onaylananlar */}
              <div className="space-y-1">
                <div className="flex items-center text-xs text-gray-600">
                  <FaCircleCheck className="mr-1 text-green-500" />
                  <span>Onaylananlar</span>
                </div>
                <div className="font-semibold text-green-600">
                  {formatTurkishLira(totals.approved.investments.amount)} ₺
                </div>
                <div className="text-xs text-gray-500">{totals.approved.investments.count} işlem</div>
              </div>
              {/* Bekleyenler */}
              <div className="space-y-1">
                <div className="flex items-center text-xs text-gray-600">
                  <FaClock className="mr-1 text-blue-500" />
                  <span>Bekleyenler</span>
                </div>
                <div className="font-semibold text-blue-600">
                  {formatTurkishLira(totals.pending.investments.amount)} ₺
                </div>
                <div className="text-xs text-gray-500">{totals.pending.investments.count} işlem</div>
              </div>
            </div>
          </div>
          {/* Çekim Bölümü */}
          <div className="rounded-lg border border-gray-200 px-4 py-2">
            <h3 className="text-xs font-medium text-gray-700 mb-4 flex items-center">
              <FaMoneyBillTransfer className="mr-2 text-emerald-500" />
              Çekim
            </h3>
            <div className="grid grid-cols-2 gap-4">
              {/* Onaylananlar */}
              <div className="space-y-1">
                <div className="flex items-center text-xs text-gray-600">
                  <FaCircleCheck className="mr-1 text-green-500" />
                  <span>Onaylananlar</span>
                </div>
                <div className="font-semibold text-green-600">
                  {formatTurkishLira(totals.approved.withdrawals.amount)} ₺
                </div>
                <div className="text-xs text-gray-500">{totals.approved.withdrawals.count} işlem</div>
              </div>
              {/* Bekleyenler */}
              <div className="space-y-1">
                <div className="flex items-center text-xs text-gray-600">
                  <FaClock className="mr-1 text-blue-500" />
                  <span>Bekleyenler</span>
                </div>
                <div className="font-semibold text-blue-600">
                  {formatTurkishLira(totals.pending.withdrawals.amount)} ₺
                </div>
                <div className="text-xs text-gray-500">{totals.pending.withdrawals.count} işlem</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BankaCardBox;

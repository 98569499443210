import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const BankLogsTable = () => {
    const [logs, setLogs] = useState([]);
    const [users, setUsers] = useState({});
    const [accounts, setAccounts] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [logsPerPage, setLogsPerPage] = useState(10);

    const fetchUserDetails = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            const userMap = response.data.data.reduce((acc, user) => {
                acc[user.id] = user;
                return acc;
            }, {});
            return userMap;
        } catch (error) {
            console.error("Error fetching users:", error);
            return {};
        }
    };

    const fetchAccountDetails = async (accountId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/bank/${accountId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error(`Error fetching account ${accountId}:`, error);
            return null;
        }
    };

    const fetchLogs = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/bank/logs`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            // Filter logs to only include 'Ekle' and 'Çıkar' transactions
            const filteredLogs = response.data.data.filter(log =>
                ['Ekle', 'Çıkar'].includes(log.transactionType)
            );

            const sortedLogs = filteredLogs.sort((a, b) =>
                new Date(b.created_at) - new Date(a.created_at)
            );

            // Fetch user details
            const userDetails = await fetchUserDetails();
            setUsers(userDetails);

            // Fetch account details for each unique accountId
            const uniqueAccountIds = [...new Set(sortedLogs.map(log => log.accountId))];
            const accountDetails = {};
            await Promise.all(
                uniqueAccountIds.map(async (accountId) => {
                    const account = await fetchAccountDetails(accountId);
                    if (account) {
                        accountDetails[accountId] = account;
                    }
                })
            );

            setAccounts(accountDetails);
            setLogs(sortedLogs);
            setCurrentPage(1); // Reset to first page when logs are fetched
            setError(null);
        } catch (error) {
            console.error("Error fetching logs:", error);
            setError("Failed to load bank logs. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredLogs = logs.filter(log => {
        const userName = users[log.userId]?.username || '';
        const accountName = accounts[log.accountId]?.accountName || '';
        const searchTermLower = searchTerm.toLowerCase();

        return log.explanation.toLowerCase().includes(searchTermLower) ||
            log.transactionType.toLowerCase().includes(searchTermLower) ||
            accountName.toLowerCase().includes(searchTermLower) ||
            userName.toLowerCase().includes(searchTermLower);
    });

    // Pagination Logic
    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Total page number
    const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

    const getTransactionTypeClass = (type) => {
        return type === 'Ekle'
            ? 'bg-green-200 text-emerald-600'
            : 'bg-red-200 text-red-600';
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    return (
        <div className='top-0 bottom-0 h-screen right-0 left-0'>
            <div className="container-fluid mx-auto p-3">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-4 bg-gray-300">
                        <div className="flex items-center space-x-4">
                            <div className="flex-1">
                                <input
                                    type="search"
                                    placeholder="İşlem/Hesap/Kullanıcı Ara..."
                                    className="w-full px-4 py-1 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        setCurrentPage(1); // Reset to first page when search term changes
                                    }}
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <label className="text-xs">Sayfa Başına:</label>
                                <select
                                    value={logsPerPage}
                                    onChange={(e) => {
                                        setLogsPerPage(Number(e.target.value));
                                        setCurrentPage(1); // Reset to first page when page size changes
                                    }}
                                    className="text-xs px-2 py-1 border rounded"
                                >
                                    {[10, 25, 50, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                onClick={fetchLogs}
                                className="px-4 py-1 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600 transition-colors"
                            >
                                Yenile
                            </button>
                        </div>
                    </div>

                    {error && (
                        <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                            <p className="font-medium">Hata!</p>
                            <p>{error}</p>
                        </div>
                    )}

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-800">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        ID
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Hesap Adı
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Banka Adı
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Hesap No
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        İşlemi Yapan
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        İşlem Türü
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Miktar
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Önceki Bakiye
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Yeni Bakiye
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Açıklama
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                        Tarih
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {currentLogs.map((log) => (
                                    <tr key={log.id} className={`${getTransactionTypeClass(log.transactionType)}`}>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                            {log.id}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                            {accounts[log.accountId]?.accountName || 'Tanımsız Hesap'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                            {accounts[log.accountId]?.bankName || 'Tanımsız Hesap'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                            {accounts[log.accountId]?.accountNumber || 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs font-medium">
                                            <span className='border-hidden p-1 bg-red-400 text-xs rounded-md text-white'>
                                                {users[log.userId]?.username || 'Unknown User'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getTransactionTypeClass(log.transactionType)}`}>
                                                {log.transactionType}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap pr-2 text-xs text-gray-900">
                                            {log.amount.toLocaleString('tr-TR')} ₺
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-center text-xs text-gray-900">
                                            {log.previousBalance.toLocaleString('tr-TR')} ₺
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-center text-xs text-gray-900">
                                            {log.newBalance.toLocaleString('tr-TR')} ₺
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 max-w-xs truncate">
                                            {log.explanation}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                            {new Date(log.created_at).toLocaleString("tr-TR")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {filteredLogs.length === 0 && (
                        <div className="text-center py-6 text-gray-500">
                            Aradığın kriterde bir işlem bulunamadı.
                        </div>
                    )}

                    {filteredLogs.length > 0 && (
                        <div className="flex justify-between items-center p-4 bg-gray-100">
                            <div className="text-xs text-gray-600">
                                Toplam <span className='font-bold'>{filteredLogs.length}</span> kayıttan {indexOfFirstLog + 1} - {Math.min(indexOfLastLog, filteredLogs.length)} arası gösteriliyor
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className="px-3 py-1 text-xs bg-emerald-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                                >
                                    Önceki
                                </button>
                                <div className="flex items-center space-x-2">
                                    {[...Array(totalPages)].map((_, index) => (
                                        <button
                                            key={index}
                                            onClick={() => paginate(index + 1)}
                                            className={`w-8 h-8 text-xs rounded ${currentPage === index + 1
                                                ? 'bg-emerald-500 text-white'
                                                : 'bg-white text-gray-700 border'
                                                }`}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                                <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className="px-3 py-1 text-xs bg-emerald-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                                >
                                    Sonraki
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BankLogsTable;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import KasaAddModal from '../components/KasaAdd'
import KasaSubtractModal from '../components/KasaSub'
import KasaTransferModal from '../components/KasaTransfer'
import RefsLogsModal from '../components/RefsLogs'

const BASE_URL = process.env.REACT_APP_API_URL;

const Kasalar = () => {
    const [kasalar, setKasalar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRef, setSelectedRef] = useState(null);
    const [logsModal, setLogsModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [subtractModal, setSubtractModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchRefsData = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/refs/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });
            const sortedRefs = response.data.data;
            setKasalar(sortedRefs);
            setError(null);
        } catch (error) {
            console.error("Error fetching refs:", error);
            setError("Failed to load refs. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRefsData();
    }, []);

    const handleRefLogs = (ref) => {
        setSelectedRef(ref);
        setLogsModal(true);
    };

    const handleAddBalance = (ref) => {
        setSelectedRef(ref);
        setAddModal(true);
    };

    const handleSubtractBalance = (ref) => {
        setSelectedRef(ref);
        setSubtractModal(true);
    };

    const handleTransfer = (ref) => {
        setSelectedRef(ref);
        setTransferModal(true);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    const filteredRefs = kasalar.filter(ref =>
        ref.refName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='top-0 bottom-0 right-0 left-0 h-screen'>
        <div className="container-fluid p-3 border mx-auto">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">


                {/* Search and Refresh Section */}
                <div className="p-3 bg-gray-300">
                    <div className="flex items-center space-x-4">
                        <div className="flex-1">
                            <input
                                type="search"
                                placeholder="Kasa Ara..."
                                className="w-full px-4 py-1 text-xs rounded-lg border focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={fetchRefsData}
                            className="px-4 py-1 text-xs bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
                        >
                            Yenile
                        </button>
                    </div>
                </div>

                {error && (
                    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                        <p>{error}</p>
                    </div>
                )}

                <div className="overflow-x-auto">
                    <table className="w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800">
                            <tr>
                                <th className="px-4 py-2 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-2 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">İsim</th>
                                <th className="px-6 py-2 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Alınan Kasa</th>
                                <th className="px-6 py-2 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Loglar</th>
                                <th className="px-6 py-2 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Transfer</th>
                                <th className="px-6 py-2 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Kasa Takviyesi</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredRefs.map((ref) => (
                                <tr key={ref.id} className="bg-emerald-200">
                                    <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                                        {ref.id}
                                        
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                                        {ref.refName}
                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-center">
                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${ref.balance === 0 ? 'bg-gray-300 text-gray-700' :
                                                ref.balance < 5000 ? 'bg-yellow-100 text-yellow-800' :
                                                    'bg-green-100 text-green-800'
                                            }`}>
                                            {Number(ref.balance).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                        </span>
                                    </td>
                                    <td className='px-6 py-3 whitespace-nowrap text-center'>
                                        <button
                                            onClick={() => handleRefLogs(ref)}
                                            className="px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-xs"
                                        >
                                            Loglar
                                        </button>
                                    </td>
                                    <td className='px-6 py-3 whitespace-nowrap text-center'>
                                        <button
                                            onClick={() => handleTransfer(ref)}
                                            className="px-2 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                                        >
                                            Transfer
                                        </button>

                                    </td>
                                    <td className="px-6 py-3 whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center gap-2">
                                            <button
                                                onClick={() => handleAddBalance(ref)}
                                                className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors text-xs"
                                            >
                                                Ekle
                                            </button>
                                            <button
                                                onClick={() => handleSubtractBalance(ref)}
                                                className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors text-xs"
                                            >
                                                Çıkar
                                            </button>

                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {filteredRefs.length === 0 && (
                    <div className="text-center text-sm py-8 text-gray-500">
                        Kasa Bulunamadı!
                    </div>
                )}
            </div>

            {/* Modals */}
            {selectedRef && (
                <>
                    <KasaAddModal
                        show={addModal}
                        onClose={() => setAddModal(false)}
                        refId={selectedRef.refId}
                        refName={selectedRef.refName}
                        currentBalance={selectedRef.balance}
                        onSuccess={fetchRefsData}
                    />
                    <KasaSubtractModal
                        show={subtractModal}
                        onClose={() => setSubtractModal(false)}
                        refId={selectedRef.refId}
                        refName={selectedRef.refName}
                        currentBalance={selectedRef.balance}
                        onSuccess={fetchRefsData}
                    />
                    <KasaTransferModal
                        show={transferModal}
                        onClose={() => setTransferModal(false)}
                        sourceRefId={selectedRef.refId}
                        sourceRefName={selectedRef.refName}
                        currentBalance={selectedRef.balance}
                        onSuccess={fetchRefsData}
                    />
                    <RefsLogsModal
                        show={logsModal}
                        onClose={() => setLogsModal(false)}
                        refId={selectedRef.refId}
                        title={`${selectedRef.refName} - Loglar`}
                    />
                </>
            )}
        </div>
        </div>
    );
};

export default Kasalar;
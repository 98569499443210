import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp, AlertCircle, RefreshCw } from 'lucide-react';

const BASE_URL = process.env.REACT_APP_API_URL;

const DateInput = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  const handleDateChange = () => {
    if (new Date(startDate) > new Date(endDate)) {
      alert('Başlangıç tarihi bitiş tarihinden sonra olamaz.');
      return;
    }

    onDateChange({
      startDate,
      endDate
    });
  };

  return (
    <div className="flex space-x-2 items-center">
      <div className="flex items-center space-x-2">
        <label className="text-xs text-gray-600">Başlangıç</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="px-2 py-1 border rounded text-xs w-36"
        />
      </div>
      <div className="flex items-center space-x-2">
        <label className="text-xs text-gray-600">Bitiş</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="px-2 py-1 border rounded text-xs w-36"
        />
      </div>
      <button
        onClick={handleDateChange}
        className="bg-emerald-500 text-white px-3 py-1 rounded text-xs hover:bg-emerald-600 transition-colors"
      >
        Filtrele
      </button>
    </div>
  );
};

const CustomAlert = ({ children }) => (
  <div className="m-4 p-4 border-l-4 border-red-500 bg-red-50 text-red-700 flex items-center space-x-2">
    <AlertCircle className="h-4 w-4 flex-shrink-0" />
    <span>{children}</span>
  </div>
);

const AccountStatsPanel = () => {
  const [accountStats, setAccountStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [retrying, setRetrying] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });

  const fetchAccountStats = async (dateRange) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      setError("Oturum bilgisi bulunamadı. Lütfen tekrar giriş yapın.");
      setLoading(false);
      return;
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    };

    try {
      setLoading(true);
      setError(null);
      
      const [accountsResponse, investmentsResponse, withdrawalsResponse] = await Promise.all([
        axios.get(`${BASE_URL}/bank/accounts`, { headers }),
        axios.get(`${BASE_URL}/investment/`, {
          headers,
          params: {
            start_date: dateRange.startDate,
            end_date: dateRange.endDate
          }
        }),
        axios.get(`${BASE_URL}/withdrawal/`, {
          headers,
          params: {
            start_date: dateRange.startDate,
            end_date: dateRange.endDate
          }
        })
      ]);

      const stats = accountsResponse.data.data.map(account => {
        const approvedInvestments = investmentsResponse.data.data.filter(
          inv => inv.accountNumber === account.accountNumber && 
                inv.investmentStatus === "Onaylandı"
        );

        const approvedWithdrawals = withdrawalsResponse.data.data.filter(
          with_ => with_.accountNumber === account.accountNumber && 
                  with_.withdrawalStatus === "Onaylandı"
        );

        return {
          accountNumber: account.accountNumber,
          accountName: account.accountName,
          bankName: account.bankName,
          balance: account.accountBalance,
          totalInvestment: approvedInvestments.reduce((sum, inv) => sum + inv.balance, 0),
          investmentCount: approvedInvestments.length,
          totalWithdrawal: approvedWithdrawals.reduce((sum, with_) => sum + with_.balance, 0),
          withdrawalCount: approvedWithdrawals.length
        };
      });

      setAccountStats(stats);
      setCurrentDateRange(dateRange);
      setError(null);
    } catch (error) {
      let errorMessage = "İstatistikler yüklenirken bir hata oluştu.";
      
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = "Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.";
        } else if (error.response.status === 403) {
          errorMessage = "Bu bilgilere erişim yetkiniz bulunmamaktadır.";
        } else {
          errorMessage = `Sunucu hatası: ${error.response.data?.detail || error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "Sunucuya bağlanılamadı. Lütfen internet bağlantınızı kontrol edin.";
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
      setRetrying(false);
    }
  };

  const handleDateChange = (dateRange) => {
    fetchAccountStats(dateRange);
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    fetchAccountStats({ startDate: today, endDate: today });
  }, []);

  const filteredStats = accountStats.filter(stat => {
    const searchLower = searchTerm.toLowerCase();
    return (
      stat.accountNumber.toLowerCase().includes(searchLower) ||
      stat.bankName.toLowerCase().includes(searchLower) || 
      stat.accountName.toLowerCase().includes(searchLower)
    );
  });

  const handleRetry = () => {
    setRetrying(true);
    fetchAccountStats(currentDateRange);
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="flex items-center justify-center h-40">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="top-0 bottom-0 h-screen right-0 left-0">
      <div className="bg-white rounded-lg shadow-lg mb-6">
        <div className="p-4 bg-gray-300 text-white flex justify-between flex-wrap items-center">
          <h2 className="text-lg text-black font-semibold">Hesap İstatistikleri</h2>
          <div className="flex text-black items-center space-x-4">
            <DateInput onDateChange={handleDateChange} />
            <input
              type="search"
              placeholder="Hesap Ara..."
              className="px-3 py-1 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500 text-gray-800"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {error && (
              <button
                onClick={handleRetry}
                disabled={retrying}
                className="p-2 hover:bg-gray-700 rounded transition-colors duration-200"
                title="Yeniden Dene"
              >
                <RefreshCw size={20} className={retrying ? "animate-spin" : ""} />
              </button>
            )}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1 hover:bg-gray-700 rounded transition-colors duration-200"
            >
              {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
        </div>

        {error && <CustomAlert>{error}</CustomAlert>}

        {isExpanded && !error && (
          <div className="p-4 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">Hesap No</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">Banka Adı</th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Bakiye</th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Yatırım Miktarı</th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">Yatırım Adeti</th>
                  <th className="px-4 py-3 text-right text-xs font-medium text-gray-800 uppercase tracking-wider">Çekim Miktarı</th>
                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">Çekim Adeti</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredStats.map((stat, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className='flex flex-col items-start justify-start px-4 py-3 text-xs text-gray-900'>
                    <td className="text-xs text-gray-900">{stat.accountName}</td>
                    <td className="text-xs text-gray-900">TR {stat.accountNumber}</td>
                    </td>
                 
                    <td className="px-4 py-3 text-xs text-gray-900">{stat.bankName}</td>
                    <td className="px-4 py-3 text-xs text-gray-900 text-right font-medium">
                      {stat.balance.toLocaleString('tr-TR')} ₺
                    </td>
                    <td className="px-4 py-3 text-xs text-green-600 text-right font-semibold">
                      {stat.totalInvestment.toLocaleString('tr-TR')} ₺
                    </td>
                    <td className="px-4 py-3 text-xs text-center">
                      <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full">
                        {stat.investmentCount}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-xs text-red-600 text-right font-semibold">
                      {stat.totalWithdrawal.toLocaleString('tr-TR')} ₺
                    </td>
                    <td className="px-4 py-3 text-xs text-center">
                      <span className="px-2 py-1 bg-red-100 text-red-800 rounded-full">
                        {stat.withdrawalCount}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {filteredStats.length === 0 && (
              <div className="text-center py-6 text-gray-500">
                Aradığınız kriterlere uygun hesap bulunamadı.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountStatsPanel;
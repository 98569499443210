import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Krediİmg from '../img/kredi-karti.svg';

const BASE_URL = process.env.REACT_APP_API_URL;

function SiteHesaplar() {

    const [refs, setRefs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Verileri API'den çekme fonksiyonu
    const fetchRefs = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/refs/with_investments/count`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const fetchRefsData = response.data;
            setRefs(fetchRefsData);
            setError(null);
        } catch (error) {
            console.error("Error fetching refs:", error);
            setError("Failed to load refs. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRefs();
    }, []);

    // Yükleniyor durumunu kontrol etme
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    // Hata durumunu kontrol etme
    if (error) {
        return (
            <div className="min-h-screen bg-gray-50">
                <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Error</h3>
                            <div className="mt-2 text-sm text-red-700">{error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // "mgmt" kasası hariç tüm kasaların toplam bakiyesini hesaplama
    const totalBalance = refs
        .filter(ref => ref.refName !== 'mgmt')  // mgmt kasası hariç
        .reduce((acc, data) => acc + (data.balance - data.total_earned_commission), 0);

    return (
        <div className="md:max-w-sm w-full">
            <div className="bg-white rounded-md shadow-md shadow-black overflow-hidden">
                <div className="bg-emerald-600 px-6 py-3">
                    <h2 className="text-lg font-semibold text-white justify-between gap-2 flex items-center">
                        Site Kasalar
                        <img src={Krediİmg} className='h-8' alt='kredi-img' />
                    </h2>
                </div>

                {/* Toplam Miktar Kartı */}
                <div className="p-6">
                    <div className="bg-emerald-100 flex items-center justify-center flex-col rounded-lg p-4 mb-6">
                        <h3 className="text-base font-medium underline underline-offset-4 text-gray-600 mb-2">Toplam Miktar</h3>
                        <div className="text-2xl font-bold text-emerald-600">
                            {(totalBalance).toLocaleString('tr-TR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })} ₺
                        </div>
                    </div>

                    {/* Hesap Listesi */}
                    <div className="space-y-6">
                        {refs.map((refs) => (
                            <div
                                key={refs.id}
                                className="bg-white rounded-lg border border-gray-200 p-4 hover:shadow-md transition-shadow"
                            >
                                <div className="flex flex-col space-y-2">
                                    <div className="flex items-center text-sm justify-between">
                                        <span className="font-medium text-sm text-gray-900">
                                            {refs.refName}
                                        </span>
                                        <span className="text-sm text-gray-500">
                                            Kasa ID: {refs.id}
                                        </span>
                                    </div>

                                    <div className={`text-right text-sm font-semibold ${refs.balance === 0
                                        ? 'text-gray-600'
                                        : refs.balance < 5000
                                            ? 'text-yellow-600'
                                            : 'text-emerald-600'
                                        }`}>
                                        {Number((refs.balance) - (refs.total_earned_commission)).toLocaleString("tr-TR", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })} ₺
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteHesaplar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const KasaTakviyeLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchSystemOperations = async (page, pageSize) => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const skip = (page - 1) * pageSize;
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/refs/system-operations?skip=${skip}&limit=${pageSize}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        }
      );

      setLogs(response.data.data);
      setTotalCount(response.data.count);
      setError(null);
    } catch (error) {
      console.error("Error fetching system operations:", error);
      setError("Failed to load system operations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSystemOperations(currentPage, logsPerPage);
  }, [currentPage, logsPerPage]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded m-4">
        <p className="font-bold">Error</p>
        <p>{error}</p>
      </div>
    );
  }

  const filteredLogs = logs.filter(log => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      log.refName?.toLowerCase().includes(searchTermLower) ||
      log.username?.toLowerCase().includes(searchTermLower) ||
      log.type.toLowerCase().includes(searchTermLower) ||
      log.note?.toLowerCase().includes(searchTermLower)
    );
  });

  const totalPages = Math.ceil(totalCount / logsPerPage);

  const getTypeStyle = (type) => {
    return type === 'Ekle' 
      ? 'bg-green-100 text-green-800' 
      : 'bg-red-100 text-red-800';
  };

  return (
    <div className="container-full mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b">
          <div className="mt-4 flex gap-4">
            <input
              type="search"
              placeholder="Ara..."
              className="flex-1 px-4 py-2 border rounded-lg text-sm"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
            <select
              value={logsPerPage}
              onChange={(e) => {
                setLogsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              className="px-4 py-2 border cursor-pointer rounded-lg text-xs"
            >
              <option value={10}>10 Kayıt</option>
              <option value={25}>25 Kayıt</option>
              <option value={50}>50 Kayıt</option>
              <option value={100}>100 Kayıt</option>
            </select>
            <button
              onClick={() => fetchSystemOperations(currentPage, logsPerPage)}
              className="px-3 py-1 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600"
            >
              Yenile
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Kasa Adı</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">İşlem Türü</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Miktar</th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">İşlemi Yapan</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tarih</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">İşlem Notu</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredLogs.map((log) => (
                <tr key={log.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 text-xs font-medium text-gray-900">
                    {log.refName}
                  </td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex px-2 py-1 text-xs font-semibold rounded-full ${getTypeStyle(log.type)}`}>
                      {log.type}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-xs text-right font-semibold">
                    {Number(log.amount).toLocaleString('tr-TR')} ₺
                  </td>
               
                  <td className="px-6 py-4 text-xs text-center text-gray-500">
                    <span className='p-1 bg-red-400 font-sans text-xs rounded-md text-white'>{log.username}</span>
                  </td>
                  <td className="px-6 py-4 text-xs text-gray-500">
                    {new Date(log.date).toLocaleString('tr-TR')}
                  </td>

                  <td className="px-6 py-4 text-xs text-gray-500 max-w-xs truncate">
                    {log.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredLogs.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            Aradığınız kriterde bir işlem bulunamadı.
          </div>
        )}

        {totalCount > 0 && (
          <div className="px-6 py-4 bg-gray-50 border-t flex items-center justify-between">
            <span className="text-sm text-gray-700">
              Toplam <span className="font-medium">{totalCount}</span> kayıttan{' '}
              <span className="font-medium">{((currentPage - 1) * logsPerPage) + 1}</span>-
              <span className="font-medium">{Math.min(currentPage * logsPerPage, totalCount)}</span> arası
            </span>
            
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm bg-white border rounded hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                Önceki
              </button>
              {[...Array(Math.min(5, totalPages))].map((_, i) => (
                <button
                  key={i}
                  onClick={() => setCurrentPage(i + 1)}
                  className={`px-3 py-1 text-sm rounded ${
                    currentPage === i + 1
                      ? 'bg-emerald-500 text-white'
                      : 'bg-white border hover:bg-gray-50'
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm bg-white border rounded hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                Sonraki
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KasaTakviyeLogs;
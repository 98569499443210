import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BankaCardBox from './BankaCardBox';
import BankaHesaplar from './BankaHesaplar';
import SiteHesaplar from './SiteHesaplar';



// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;


const Main = () => {

    const [, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedOption, setSelectedOption] = useState('banka-hesaplar');

    const handleSelectionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const getAllByUsers = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/bank/accounts`, 
            { headers: { 'Authorization': `Bearer ${token}` } });
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to load account data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllByUsers();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 ">
                <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Error</h3>
                            <div className="mt-2 text-sm text-red-700">{error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="min-h-screen bg-gray-50  ">
            <main className="container-fluid mx-auto px-3 py-4">
                <div className="flex flex-row items-center flex-wrap flex-grow lg:items-start justify-center gap-6 ">
                    {/* Left Column - Account Summary */}
                    <div className='flex w-full items-start gap-4 justify-center flex-wrap flex-row'>
                        <div className='flex flex-col border rounded-xl bg-slate-200 p-2  w-full md:w-96 items-start'>
                            <select value={selectedOption}
                                onChange={handleSelectionChange}
                                className="border border-gray-300 p-1 my-1 mb-2 text-xs rounded-md">
                                <option value='site-hesaplar'>Site Kasalar</option>
                                <option value='banka-hesaplar'>Tüm Hesaplar</option>
                            </select>

                         {selectedOption === 'banka-hesaplar' && <BankaHesaplar />}
                         {selectedOption === 'site-hesaplar' && <SiteHesaplar />}
                           

                        </div>
                        {/* Right Column - Cards */}
                        <div className="flex flex-1 items-start lg:justify-start justify-center ">
                            <div className="flex flex-row items-center flex-wrap justify-center gap-4">
                                {/* Statistics Cards */}

                                <BankaCardBox />

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Main;
import React from 'react'
import HesapEklemeleri from '../components/HesapEklemeleri'

function HesapEklemeleriPage() {

  return (

    <HesapEklemeleri />

  )
}

export default HesapEklemeleriPage
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const WithdrawalLogsTable = () => {
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState({});
  const [withdrawals, setWithdrawals] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);

  const fetchUserDetails = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${BASE_URL}/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching user ${userId}:`, error);
      return null;
    }
  };

  const fetchWithdrawalDetails = async (withdrawalId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${BASE_URL}/withdrawal/${withdrawalId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching withdrawal ${withdrawalId}:`, error);
      return null;
    }
  };

  const fetchLogs = async () => {
    const token = localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/withdrawal/logs`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      const sortedLogs = response.data.data.sort((a, b) =>
        new Date(b.created_at) - new Date(a.created_at)
      );

      // Fetch user details for each unique userId
      const uniqueUserIds = [...new Set(sortedLogs.map(log => log.userId))];
      const userDetails = {};
      await Promise.all(
        uniqueUserIds.map(async (userId) => {
          const user = await fetchUserDetails(userId);
          if (user) {
            userDetails[userId] = user;
          }
        })
      );

      // Fetch withdrawal details for each unique withdrawalId
      const uniqueWithdrawalIds = [...new Set(sortedLogs.map(log => log.withdrawalId))];
      const withdrawalDetails = {};
      await Promise.all(
        uniqueWithdrawalIds.map(async (withdrawalId) => {
          const withdrawal = await fetchWithdrawalDetails(withdrawalId);
          if (withdrawal) {
            withdrawalDetails[withdrawalId] = withdrawal;
          }
        })
      );

      setUsers(userDetails);
      setWithdrawals(withdrawalDetails);
      setLogs(sortedLogs);
      setError(null);
    } catch (error) {
      console.error("Error fetching logs:", error);
      setError("Failed to load withdrawal logs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredLogs = logs.filter(log => {
    const userName = users[log.userId]?.username || '';
    const withdrawalInfo = withdrawals[log.withdrawalId];
    const searchTermLower = searchTerm.toLowerCase();

    return log.note.toLowerCase().includes(searchTermLower) ||
      userName.toLowerCase().includes(searchTermLower) ||
      withdrawalInfo?.accountName?.toLowerCase().includes(searchTermLower) ||
      withdrawalInfo?.accountNumber?.toLowerCase().includes(searchTermLower) ||
      log.transactionResult.toLowerCase().includes(searchTermLower);
  });

  // Pagination calculations
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  const getStatusTypeClass = (status) => {
    switch (status) {
      case 'Onaylandı':
        return 'bg-green-100 text-green-800';
      case 'İptal':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  const getTransactionResultClass = (result) => {
    switch (result) {
      case 'Tamamlandı':
        return 'bg-green-100 text-green-800';
      case 'Başarısız':
        return 'bg-red-100 text-red-800';
      case 'İptal Edildi':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  return (
    <div className='top-0 bottom-0 h-screen right-0 left-0'>
      <div className="container-fluid mx-auto p-3">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-4 bg-gray-300">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  type="search"
                  placeholder="İşlem/Hesap/Kullanıcı Ara..."
                  className="w-full px-4 py-1 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1); // Reset to first page when searching
                  }}
                />
              </div>
              <div className="flex items-center space-x-2">
                <label className="text-xs">Sayfa Başına:</label>
                <select
                  value={logsPerPage}
                  onChange={(e) => {
                    setLogsPerPage(Number(e.target.value));
                    setCurrentPage(1); // Reset to first page when changing logs per page
                  }}
                  className="px-2 py-1 border rounded text-xs"
                >
                  {[10, 20, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} kayıt
                    </option>
                  ))}
                </select>
                <button
                  onClick={fetchLogs}
                  className="px-4 py-1 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600 transition-colors"
                >
                  Yenile
                </button>
              </div>
            </div>
          </div>

          {error && (
            <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
              <p className="font-medium">Hata!</p>
              <p>{error}</p>
            </div>
          )}

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-800">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Çekim ID
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Hesap Adı
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Hesap No
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Hedef Hesap No
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Kullanıcı
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Durum
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Miktar
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    İşlem Sonucu
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Not
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                    Tarih
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentLogs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-100">
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      {log.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      {log.withdrawalId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      {withdrawals[log.withdrawalId]?.accountName || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      {withdrawals[log.withdrawalId]?.accountNumber || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      {withdrawals[log.withdrawalId]?.targetAccountNumber || 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                      <span className='border-hidden p-1 bg-red-400 text-xs rounded-md text-white'>
                        {users[log.userId]?.username || 'Unknown User'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusTypeClass(log.newStatus)}`}>
                        {log.newStatus}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">
                      {log.amount.toLocaleString('tr-TR')} ₺
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getTransactionResultClass(log.transactionResult)}`}>
                        {log.transactionResult}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 max-w-xs truncate">
                      {log.note}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                      {new Date(log.created_at).toLocaleString("tr-TR")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {filteredLogs.length === 0 && (
            <div className="text-center py-6 text-gray-500">
              Aradığın kriterde bir işlem bulunamadı.
            </div>
          )}

          {/* Pagination Controls */}
          {filteredLogs.length > 0 && (
            <div className="flex justify-between items-center p-4 bg-gray-100">
              <div className="text-xs text-gray-600">
                Toplam <span className='font-bold'>{filteredLogs.length}</span> kayıttan {indexOfFirstLog + 1} - {Math.min(indexOfLastLog, filteredLogs.length)} arası gösteriliyor
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-1 text-xs bg-emerald-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Önceki
                </button>
                <div className="flex items-center space-x-2">
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => paginate(index + 1)}
                      className={`w-8 h-8 text-xs rounded ${currentPage === index + 1
                          ? 'bg-emerald-500 text-white'
                          : 'bg-white text-gray-700 border'
                        }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 text-xs bg-emerald-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  Sonraki
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawalLogsTable;
import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import '../index.css'
import '../App.css'
import UsersTable from '../components/UserTable'
import AdminFormButton from '../components/AdminFormButton'

function AdminYonetimi() {
  return (
    <>
    <div>
      
      <Header></Header>
      <NavBar></NavBar>
      <AdminFormButton/> 
      <UsersTable/>
    </div>
    </>
  )
}

export default AdminYonetimi
